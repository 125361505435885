import React from 'react';
import { getMessageId, OopsReasons } from '@pcid/utils/oops';
import { Message } from '../layout';
import OopsLayout from '../oops-layout';

const NotFound = () => (
	<OopsLayout title="page.oops.title.general">
		<Message id={getMessageId(OopsReasons.NOT_FOUND)} />
	</OopsLayout>
);

export default NotFound;
