import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Layout, { Heading } from '../layout';

const OopsLayout = ({
	className,
	children,
	heading,
	title,
	...props
}) => (
	<Layout className={classNames(className, 'oops-page')} title={title} {...props}>
		<Heading id={heading} showIcon />
		{children}
	</Layout>
);

OopsLayout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	heading: PropTypes.string,
	title: PropTypes.string,
};

OopsLayout.defaultProps = {
	heading: 'page.oops.heading.general',
	title: 'page.oops.title.general',
};

export default OopsLayout;
